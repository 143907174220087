import BaseIcon from './BaseIcon';
import BaseButton from './BaseButton';
import BaseCheckbox from './BaseCheckbox';
import BaseFilterPanel from './BaseFilterPanel';
import BaseFilterPanelButton from '~components/Base/BaseFilterPanelButton';
import BaseFilterPanelCategory from './BaseFilterPanelCategory';
import BaseFilterPanelMultiSelect from '~components/Base/BaseFilterPanelMultiSelect';
import BaseFilterPanelMultiSelectTag from '~components/Base/BaseFilterPanelMultiSelectTag';
import BaseFilterPanelSelect from '~components/Base/BaseFilterPanelSelect';
import BaseInput from './BaseInput';
import BaseModal from './BaseModal';
import BasePagination from './BasePagination';
import BaseProgressLine from './BaseProgressLine';
import BaseSearch from './BaseSearch';
import BaseSelect from './BaseSelect';
import BaseSpinner from './BaseSpinner';
import BaseTable from './BaseTable';

export default [
  BaseIcon,
  BaseButton,
  BaseCheckbox,
  BaseFilterPanel,
  BaseFilterPanelButton,
  BaseFilterPanelCategory,
  BaseFilterPanelMultiSelect,
  BaseFilterPanelMultiSelectTag,
  BaseFilterPanelSelect,
  BaseModal,
  BaseInput,
  BasePagination,
  BaseProgressLine,
  BaseSearch,
  BaseSelect,
  BaseSpinner,
  BaseTable,
];
