import { createRouter, createWebHistory } from 'vue-router';
import VueBodyClass from 'vue-body-class';
import store from '@/store/index';
import { checkAuth, createPreviewPaths } from './routerMdw';

const { siteVersion } = store.state.settings;

const routes = [
  {
    path: '/exercises-catalog',
    name: 'exercises-catalog',
    meta: {
      title: 'Каталог заданий',
      bodyClass: 'coloredBackground',
      permissions: ['TEACHER'],
    },
    component: () => import('~pages/ExercisesCatalog'),
    children: [
      {
        path: '',
        name: 'exercises-catalog-main',
        meta: {
          title: 'Каталог заданий',
          permissions: ['TEACHER'],
        },
        component: () => import('~components/ExercisesCatalog/Catalog'),
      },
      createPreviewPaths('exercisesCatalog'),
    ],
  },
  {
    path: '/teacher-works',
    name: 'teacher-works',
    component: () => import('~pages/TeacherWorks'),
    meta: {
      title: 'Мои работы',
      permissions: ['TEACHER'],
    },
    children: [
      {
        path: '',
        name: 'teacher-works-list',
        component: () => import('~components/TeacherWorks/WorkList'),
        meta: { title: 'Мои работы' },
      },
      {
        path: ':id',
        name: 'teacher-works-work',
        component: () => import('~components/TeacherWorks/Work'),
      },
      {
        path: ':id/:testId',
        name: 'testChecking',
        component: () => import('~layout/ExerciseLayout'),
        meta: {
          layout: 'EmptyLayout',
          bodyClass: 'coloredBackground',
        },
        children: [
          {
            path: '',
            name: 'testChecking-intro',
            meta: { title: 'Тестирование' },
            component: () => import('~pages/Exercise'),
            props: (route) => ({
              testId: route.params.testId,
              unitKey: 1,
              pageKey: 1,
            }),
          },
          {
            path: ':unitKey',
            name: 'testChecking-unit',
            meta: { title: 'Тестирование' },
            component: () => import('~pages/Exercise'),

            props: (route) => ({
              testId: route.params.testId,
              unitKey: Number(route.params.unitKey),
              pageKey: 1,
            }),
          },
          {
            path: ':unitKey/:pageKey',
            name: 'testChecking-question',
            meta: { title: 'Тестирование' },
            component: () => import('~pages/Exercise'),

            props: (route) => ({
              testId: route.params.testId,
              unitKey: Number(route.params.unitKey),
              pageKey: Number(route.params.pageKey),
            }),
          },
        ],
      },
      createPreviewPaths('teacherWorks'),
    ],
  },
  {
    path: '/student-tests',
    name: 'student-tests',
    meta: {
      title: 'Мои работы',
      permissions: ['STUDENT'],
    },
    component: () => import('~pages/StudentTests'),
    children: [
      {
        path: '',
        name: 'student-tests-list',
        meta: {
          title: 'Мои работы',
        },
        component: () => import('~components/StudentTests/Tests'),
      },
      {
        path: ':testId',
        name: 'testExecuting',
        component: () => import('~layout/ExerciseLayout'),
        meta: {
          layout: 'EmptyLayout',
          bodyClass: 'coloredBackground',
        },
        children: [
          {
            path: '',
            name: 'testExecuting-intro',
            meta: { title: 'Тестирование' },
            component: () => import('~pages/Exercise'),
            props: (route) => ({
              testId: route.params.testId,
              unitKey: 1,
              pageKey: 1,
            }),
          },
          {
            path: ':unitKey',
            name: 'testExecuting-unit',
            meta: { title: 'Тестирование' },
            component: () => import('~pages/Exercise'),

            props: (route) => ({
              testId: route.params.testId,
              unitKey: Number(route.params.unitKey),
              pageKey: 1,
            }),
          },
          {
            path: ':unitKey/:pageKey',
            name: 'testExecuting-question',
            meta: { title: 'Тестирование' },
            component: () => import('~pages/Exercise'),

            props: (route) => ({
              testId: route.params.testId,
              unitKey: Number(route.params.unitKey),
              pageKey: Number(route.params.pageKey),
            }),
          },
        ],
      },
    ],
  },
  {
    path: '/grades-and-students',
    name: 'grades-and-students',
    component: () => import('~pages/GradesAndStudents'),
    meta: {
      title: 'Классы и ученики',
      permissions: ['RESPONDER_SCHOOL'],
    },
    children: [
      {
        path: '',
        name: 'grades-list',
        component: () => import('~components/GradesAndStudents/GradesAndStudentsGradesList'),
      },
      {
        path: ':gradeId',
        name: 'grade-student',
        component: () => import('~components/Grades/GradeStudents'),
        props: (route) => ({
          gradeId: route.params.gradeId,
          schoolId: route.params.schoolId,
        }),
      },
    ],
  },
  {
    path: '/students',
    name: 'students',
    component: () => import('~pages/Students'),
    meta: {
      title: 'Ученики',
      permissions: ['RESPONDER_REGION'],
    },
    children: [
      {
        path: '',
        name: 'students',
        component: () => import('~components/Grades/ResponderRegion/Students'),
      },
    ],
  },
  {
    path: '/school-and-teachers',
    name: 'school-and-teachers',
    component: () => import('~pages/SchoolsAndTeachers'),
    meta: {
      title: 'Школы и учителя',
      permissions: ['ADMIN', 'RESPONDER_REGION'],
    },
    children: [
      {
        path: '',
        name: 'switch',
        meta: {
          title: 'Школы и учителя',
        },
        component: () => import('~components/Schools/Switch'),
      },
      {
        path: ':schoolId',
        name: 'school-grade',
        component: () => import('~components/Grades/GradesList'),
        props: (route) => ({
          schoolId: route.params.schoolId,
        }),
      },
      {
        path: ':schoolId/:gradeId',
        name: 'school-student',
        component: () => import('~components/Grades/GradeStudents'),
        props: (route) => ({
          gradeId: route.params.gradeId,
          schoolId: route.params.schoolId,
          gradeName: route.params.gradeName,
        }),
      },
    ],
  },
  {
    path: '/teachers',
    name: 'teachers-list',
    component: () => import('~pages/Teachers'),
    meta: {
      title: 'Учителя',
      permissions: ['RESPONDER_SCHOOL'],
    },
  },
  {
    path: '/404',
    name: '404',
    meta: {
      layout: 'empty-layout',
      noAuth: true,
      bodyClass: 'coloredBackground',
    },
    component: () => import('~pages/Page404'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const vueBodyClass = new VueBodyClass(routes); // noinspection JSCheckFunctionSignatures
const router = createRouter({ history: createWebHistory(), routes });

router.beforeEach(checkAuth);
router.beforeEach((to, from, next) => {
  document.body.id = siteVersion;
  document.title = to?.meta.title || '';
  vueBodyClass.guard(to, next);
});

export default router;
