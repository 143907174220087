export default {
  navs: {
    Профиль: 'Профиль',
    'Школы и учителя': 'Школы и учителя',
    'Каталог заданий': 'Каталог заданий',
    'Мои классы': 'Мои классы',
    'Мои работы': 'Мои работы',
    'Список пользователей': 'Список пользователей',
    'Создать пользователя': 'Создать пользователя',
    Учителя: 'Учителя',
    Ответственные: 'Ответственные',
    Ученики: 'Ученики',
    'Классы и ученики': 'Классы и ученики',
    Статистика: 'Статистика',
    Помощь: 'Помощь',
  },
  themes: {
    creative: 'Креативное мышление',
    mathlit: 'Математическая грамотность',
  },
  student: '0 учеников | {n} ученик | {n} ученика | {n} учеников',

  Вопрос: 'Вопрос',

  Язык: 'Язык',
  Ученик: 'Ученик',
  // Страница регистраии и входа
  'Все права защищены.': 'Все права защищены.',
  '© 2021, ТОО «AmanSultan» Все права защищены.': '© 2021, ТОО «AmanSultan» Все права защищены.',
  'Тренажеры по развитию функциональной грамотности.': 'Тренажеры по развитию функциональной грамотности.',
  'Международная программа по оценке образовательных достижений учащихся.':
    'Международная программа по оценке образовательных достижений учащихся.',
  'С паролем': 'С паролем',
  'Я задал пароль': 'Я задал пароль',
  'Задать пароль': 'Задать пароль',
  'По коду': 'По коду',
  'Введите код': 'Введите код',
  'Выдан учителем': 'Выдан учителем',
  'Через ERG Digital Mektebi': 'Через ERG Digital Mektebi',
  'Через Almaty Sifrlyq Mektebi': 'Через Almaty Sifrlyq Mektebi',
  'Через AmanSultan': 'Через AmanSultan',
  'Войти через ERG Digital Mektebi': 'Войти через ERG Digital Mektebi',
  Авторизация: 'Авторизация',
  Почта: 'Почта',
  'Введите свой Email': 'Введите свой Email',
  Пароль: 'Пароль',
  'Введите свой пароль': 'Введите свой пароль',
  'Запомнить меня': 'Запомнить меня',
  Войти: 'Войти',
  'Поле обязательно для заполнения!': 'Поле обязательно для заполнения!',
  userAssign: {
    text: 'Нажимая кнопку "Войти", я принимаю {0}',
    link: 'пользовательское соглашение',
  },

  // Страница классов
  'Мои классы': 'Мои классы',
  'Просмотр классов': 'Просмотр классов',
  'Добавить класс': 'Добавить класс',
  'Назовите ваш класс': 'Назовите ваш класс',
  'Введите номер и название класса': 'Введите номер и название класса',
  'Создать класс': 'Создать класс',
  'ФИО ученика': 'ФИО ученика',
  Код: 'Код',
  'Код для входа': 'Код для входа',
  'Распечатать все коды': 'Распечатать все коды',
  'Вернуться к списку': 'Вернуться к списку',
  'Вернуться к классам': 'Вернуться к классам',
  'Добавить ученика': 'Добавить ученика',
  'Удалить класс': 'Удалить класс',
  'E-mail': 'E-mail',
  'Загрузить список': 'Загрузить список',
  'Редактирование ученика': 'Редактирование ученика',
  'Добавление ученика': 'Добавление ученика',
  'Вы уверены, что хотите удалить': 'Вы уверены, что хотите удалить',
  'Добавьте учеников': 'Добавьте учеников',
  'Вернуться в каталог': 'Вернуться в каталог',
  'Добавление класса': 'Добавление класса',
  'Скопируйте и вставьте список учеников или заполните самостоятельно':
    'Скопируйте и вставьте список учеников или заполните самостоятельно',
  Добавить: 'Добавить',
  'Добавление учеников': 'Добавление учеников',
  'Показывать актуальный список': 'Показывать актуальный список',

  // Страница учителей
  'Добавление учителя': 'Добавление учителя',
  Предмет: 'Предмет',
  'Редактирование учителя': 'Редактирование учителя',
  'Отправить приглашение': 'Отправить приглашение',
  'Сохранить изменения': 'Сохранить изменения',
  'Редактирование пароля': 'Редактирование пароля',
  Сбросить: 'Сбросить',
  Школа: 'Школа',
  'Вы уверены, что хотите удалить учителя': 'Вы уверены, что хотите удалить учителя',

  // Страница школ
  'Редактирование школы и администратора': 'Редактирование школы и администратора',
  'Название школы': 'Название школы',
  'Вы уверены, что хотите удалить школу': 'Вы уверены, что хотите удалить школу',
  'Добавление школы и администратора': 'Добавление школы и администратора',
  'Город / населенный пункт': 'Город / населенный пункт',
  'Администратор школы': 'Администратор школы',
  'Добавление нового администратора': 'Добавление нового администратора',
  Телефон: 'Телефон',

  // Страница ответственного
  'Повторите пароль': 'Повторите пароль',
  'Вы уверены, что хотите удалить ответственного': 'Вы уверены, что хотите удалить ответственного',

  // Страница работ учителя
  'Мои работы': 'Мои работы',
  'Название модуля': 'Название модуля',
  'Вернуться к моим работам': 'Вернуться к моим работам',
  Класс: 'Класс',
  'Дата выдачи': 'Дата выдачи',
  Ссылка: 'Ссылка',
  'Выполнение задания': 'Выполнение задания',
  Статус: 'Статус',
  Выдано: 'Выдано',
  Выполняется: 'Выполняется',
  'Требует проверки': 'Требует проверки',
  'Проверка завершена': 'Проверка завершена',
  ФИО: 'ФИО',
  'Не начато': 'Не начато',
  Сегодня: 'Сегодня {in}',
  Вчера: 'Вчера {in}',

  // Каталог уроков
  Каталог: 'Каталог',
  'Математическая грамотность': 'Математическая грамотность',
  'Креативное мышление': 'Креативное мышление',
  'Название темы': 'Название темы',
  'Назначить задание': 'Назначить задание',
  'Название задания': 'Название задания',
  'Ограничение по времени в минутах': 'Ограничение по времени в минутах',
  Классы: 'Классы',
  Группы: 'Группы',
  Ученики: 'Ученики',
  Назначить: 'Назначить',
  'Предварительный просмотр': 'Предварительный просмотр',
  'Задание назначено. Перейти к просмотру назначенных заданий?': 'Задание назначено. Перейти к просмотру назначенных заданий?',
  Перейти: 'Перейти',
  'Критерии оценки': 'Критерии оценки',

  // Профиль
  'Профиль учителя': 'Профиль учителя',
  Фамилия: 'Фамилия',
  Имя: 'Имя',
  Отчество: 'Отчество',
  Страна: 'Страна',
  Регион: 'Регион',
  Район: 'Район',
  Город: 'Город',
  'Населённый пункт': 'Населённый пункт',
  'Образовательная организация': 'Образовательная организация',
  'Номер телефона': 'Номер телефона',
  'Личные данные': 'Личные данные',
  'Учебные заведения': 'Учебные заведения',
  Далее: 'Далее',
  'Выберите школу из списка или начните вводить номер или название школы':
    'Выберите школу из списка или начните вводить номер или название школы',
  'Информация профиля': 'Информация профиля',
  Логин: 'Логин',
  Идентификатор: 'Идентификатор',
  Роль: 'Роль',
  'Дата регистрации': 'Дата регистрации',
  'Редактировать данные': 'Редактировать данные',
  Сохранить: 'Сохранить',

  // Страница тестов ученика
  'Название работы': 'Название работы',
  Начато: 'Начато',
  Время: 'Время',
  Прогресс: 'Прогресс',
  Начать: 'Начать',
  Продолжить: 'Продолжить',
  'На проверке': 'На проверке',

  // Модалка помощи урока
  Помощь: 'Помощь',
  'Здесь вы можете найти информацию о тесте и о том, как отвечать на вопросы.':
    'Здесь вы можете найти информацию о тесте и о том, как отвечать на вопросы.',
  'Нажмите на ссылку или используйте прокрутку, чтобы найти необходимую вам информацию.':
    'Нажмите на ссылку или используйте прокрутку, чтобы найти необходимую вам информацию.',
  'Перемещение по тесту и оценка продвижения по тесту': 'Перемещение по тесту и оценка продвижения по тесту',
  'Нажмите на стрелку ДАЛЕЕ, чтобы перейти к следующему вопросу или блоку вопросов.':
    'Нажмите на стрелку ДАЛЕЕ, чтобы перейти к следующему вопросу или блоку вопросов.',
  'Нажмите на стрелку НАЗАД, чтобы вернуться к предыдущему вопросу или блоку вопросов.':
    'Нажмите на стрелку НАЗАД, чтобы вернуться к предыдущему вопросу или блоку вопросов.',
  'Если переход назад невозможен, стрелка будет окрашена в серый цвет.':
    'Если переход назад невозможен, стрелка будет окрашена в серый цвет.',
  'Каждый квадрат индикатора продвижения соответствует одному вопросу.':
    'Каждый квадрат индикатора продвижения соответствует одному вопросу',
  'Задание, над которым вы работаете в настоящее время, окрашено в белый цвет.':
    'Задание, над которым вы работаете в настоящее время, окрашено в белый цвет.',
  'Индикатор показывает оставшееся время на выполнение задания.': 'Индикатор показывает оставшееся время на выполнение задания.',
  'Как отвечать на вопросы': 'Как отвечать на вопросы',
  'Отметьте нужный вариант ответа:': 'Отметьте нужный вариант ответа:',
  'такие вопросы имеют только один правильный ответ.': ' такие вопросы имеют только один правильный ответ.',
  'Отметьте нужный вариант ответа. При этом будет закрашен центр отмеченного кружка.':
    'Отметьте нужный вариант ответа. При этом будет закрашен центр отмеченного кружка.',
  'Чтобы изменить свой ответ, отметьте другой вариант ответа.': 'Чтобы изменить свой ответ, отметьте другой вариант ответа.',
  'Отметьте один или несколько вариантов ответа:': 'Отметьте один или несколько вариантов ответа:',
  'такие вопросы могут иметь более одного правильного ответа.': ' такие вопросы могут иметь более одного правильного ответа.',
  'Отметьте один или несколько вариантов ответа. В каждой выбранной ячейке появится галочка.':
    'Отметьте один или несколько вариантов ответа. В каждой выбранной ячейке появится галочка.',
  'Чтобы изменить свой ответ, нажмите на выбранную вами ячейку ещё раз. Галочка исчезнет.':
    'Чтобы изменить свой ответ, нажмите на выбранную вами ячейку ещё раз. Галочка исчезнет.',
  'Отметьте график или изображение:': 'Отметьте график или изображение:',
  'Нажмите на график или изображение, и выбранный вами объект подсветится.':
    'Нажмите на график или изображение, и выбранный вами объект подсветится.',
  'Чтобы изменить свой ответ, нажмите на график или изображение ещё раз. Подсветка исчезнет.':
    'Чтобы изменить свой ответ, нажмите на график или изображение ещё раз. Подсветка исчезнет.',
  'Запишите ответ:': 'Запишите ответ:',
  'Щёлкните мышью внутри текстового поля и запишите свой ответ при помощи клавиатуры.':
    'Щёлкните мышью внутри текстового поля и запишите свой ответ при помощи клавиатуры.',
  'Используйте клавишу Backspace, чтобы стереть написанное.': 'Используйте клавишу Backspace, чтобы стереть написанное.',
  'Запишите цифрами ответ на вопрос:': 'Запишите цифрами ответ на вопрос:',
  'вы НЕ МОЖЕТЕ использовать клавиши с буквами, чтобы ответить на вопросы такого типа. Используйте клавиши с цифрами и любые из следующих пяти клавиш:':
    ' вы НЕ МОЖЕТЕ использовать клавиши с буквами, чтобы ответить на вопросы такого типа. Используйте клавиши с цифрами и любые из следующих пяти клавиш:',
  'Запятая [ , ]': 'Запятая [ , ]',
  'Точка [ . ]': 'Точка [ . ]',
  'Слэш (косая черта) [ / ] для записи обыкновенных дробей': 'Слэш (косая черта) [ / ] для записи обыкновенных дробей',
  'Дефис [ - ] для записи отрицательных чисел': 'Дефис [ - ] для записи отрицательных чисел',
  ПРОБЕЛ: 'ПРОБЕЛ',
  'Используйте выпадающее меню:': 'Используйте выпадающее меню:',
  'Нажмите на стрелку.': 'Нажмите на стрелку.',
  'Выберите вариант ответа.': 'Выберите вариант ответа.',
  'Вариант ответа, который вы выбрали, появится в верхней строке меню.':
    'Вариант ответа, который вы выбрали, появится в верхней строке меню.',
  'Чтобы изменить свой ответ, повторите описанные выше шаги, выбрав при этом другой вариант ответа.':
    'Чтобы изменить свой ответ, повторите описанные выше шаги, выбрав при этом другой вариант ответа.',
  'Используйте метод «Перетащить и оставить»:': 'Используйте метод «Перетащить и оставить»:',
  'Нажмите и удерживайте кнопку мыши над объектом, который вы хотите перетащить.':
    'Нажмите и удерживайте кнопку мыши над объектом, который вы хотите перетащить.',
  'Удерживая кнопку мыши нажатой, перетащите объект в нужное место.':
    'Удерживая кнопку мыши нажатой, перетащите объект в нужное место.',
  'Отпустите кнопку мыши. Объект окажется в выбранном вами месте.':
    'Отпустите кнопку мыши. Объект окажется в выбранном вами месте.',
  'Чтобы изменить свой ответ, перетащите объект в его первоначальное положение.':
    'Чтобы изменить свой ответ, перетащите объект в его первоначальное положение.',
  'Перемещение по страницам': 'Перемещение по страницам',
  'Перемещение по страницам:': 'Перемещение по страницам:',
  'некоторые блоки заданий содержат материал для чтения, который расположен более чем на одной странице.':
    ' некоторые блоки заданий содержат материал для чтения, который расположен более чем на одной странице.',
  'Для перемещения по страницам нажмите на номер страницы.': 'Для перемещения по страницам нажмите на номер страницы.',
  'Для каждого вопроса страница 1 всегда отображается первой.': 'Для каждого вопроса страница 1 всегда отображается первой.',
  'Вы можете переходить вперёд и назад по страницам в любой момент при выполнении данного блока заданий.':
    'Вы можете переходить вперёд и назад по страницам в любой момент при выполнении данного блока заданий.',

  // Дропзона для скачивания файлов с учителями, учениками, школами.
  'Перетащите в эту область файл формата xls': 'Перетащите в эту область файл формата xls',
  'Скачать шаблон xls': 'Скачать шаблон xls',
  Загрузить: 'Загрузить',

  // Критерии оценки в заданиях
  'Содержательная область оценки': 'Содержательная область оценки',
  'Компетентностная область оценки': 'Компетентностная область оценки',
  'СИСТЕМА ОЦЕНИВАНИЯ': 'СИСТЕМА ОЦЕНИВАНИЯ',
  'ПРИМЕРЫ КАТЕГОРИЙ': 'ПРИМЕРЫ КАТЕГОРИЙ',
  Обычные: 'Обычные',
  Необычные: 'Необычные',

  'Загрузка таблицы...': 'Загрузка таблицы...',
  'Список успешно загружен': 'Список успешно загружен',
  'Подтвердите действие': 'Подтвердите действие',
  Да: 'Да',
  Нет: 'Нет',
  класс: 'класс',
  'Ученик удален': 'Ученик удален',
  'К вашему профилю не привязана школа. Вы не можете просматривать и редактировать классы.':
    'К вашему профилю не привязана школа. Вы не можете просматривать и редактировать классы.',
  'К вашему профилю не привязана школа. Вы не можете просматривать и редактировать учителей.':
    'К вашему профилю не привязана школа. Вы не можете просматривать и редактировать учителей.',
  'Администраторы школы': 'Администраторы школы',
  'Редактирование школы': 'Редактирование школы',
  'Восстановление пароля': 'Восстановление пароля',
  'Изменить пароль': 'Изменить пароль',
  Отправить: 'Отправить',
  Буква: 'Буква',
  'Для вашей роли отключена синхронизация': 'Для вашей роли отключена синхронизация',
  'Синхронизация отключена: ваш профиль не привязан к аккаунту ОШ или аккаунта не существует.':
    'Синхронизация отключена: ваш профиль не привязан к аккаунту ОШ или аккаунта не существует.',
  'Синхронизация отключена: у вашего профиля осутствует логин.': 'Синхронизация отключена: у вашего профиля осутствует логин.',
  'Скачать статистику школы': 'Скачать статистику школы',
  'Скачать статистику учителей': 'Скачать статистику учителей',
  'Скачать статистику': 'Скачать статистику',
  'Скачать статистику классов': 'Скачать статистику классов',
  'Добавить школу': 'Добавить школу',
  'Скачать статистику по району': 'Скачать статистику по району',
  'Скачать статистику по региону': 'Скачать статистику по региону',
};
