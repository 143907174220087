module.exports = {
  LOCALE: process.env.LOCAL,
  APP_URL: process.env.APP_URL,
  MAIN_APP_URL: process.env.MAIN_APP_URL,

  'https://fl.amansultan.kz': {
    APP_URL_OS: 'https://amansultan.kz',
    APP_URL_LOGIN_OS: 'https://login.amansultan.kz',
  },
  'https://preprod.fl.amansultan.kz': {
    APP_URL_OS: 'https://amansultan.kz',
    APP_URL_LOGIN_OS: 'https://amansultan.kz',
  },
  'https://fl.2035school.ru': {
    APP_URL_OS: 'https://2035school.ru',
    APP_URL_LOGIN_OS: 'https://login.2035school.ru',
  },
  'http://localhost:5005': {
    APP_URL_OS: 'http://localhost:8080',
    APP_URL_LOGIN_OS: 'http://localhost:8642',
  },
  'https://preprod.fl.2035school.ru': {
    APP_URL_OS: '',
    APP_URL_LOGIN_OS: '',
  },
};
