<template>
  <div v-if="visible">
    <TheThemeChanger v-if="dev" />
    <component :is="layout" />
    <ModalContainer />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { container } from 'jenesius-vue-modal';
import TheThemeChanger from '~components/TheThemeChanger';
import BaseLayout from '~layout/BaseLayout';
import EmptyLayout from '~layout/EmptyLayout';
import ExerciseLayout from '~layout/ExerciseLayout';

export default {
  name: 'App',
  components: {
    ModalContainer: container,
    BaseLayout,
    EmptyLayout,
    ExerciseLayout,
    TheThemeChanger,
  },
  setup() {
    const visible = !document.location.search.includes('timeCodeAuth');
    const layout = computed(() => useRoute().meta.layout || 'BaseLayout');
    const { host } = window.location;
    const dev = host === 'localhost:5005';
    return {
      layout,
      dev,
      visible,
    };
  },
};
</script>
